export default [
    {
        title: 'global.cards_dashboard',
        icon: ['fal', 'calendar-week'],
        to: { name: 'cards' },
        accessRights: 'MVP'
    },
    {
        title: 'global.chevaux',
        icon: ['fal', 'horse'],
        accessRights: 'MVP',
        children: [
            {
                title: 'menu.chevaux.tous_les_chevaux',
                icon: ['fal', 'horse'],
                to: { name: 'horseListe' }
            },
            {
                title: 'menu.chevaux.horse_archived',
                icon: ['fal', 'horse'],
                to: { name: 'horseArchivedList' },
            },
            {
                title: 'global.rechercher',
                icon: ['fal', 'search'],
                to: { name: 'horseWithoutAct' },
                accessRights: 'MVP'
            },
            {
                title: 'action.ajouter_cheval',
                icon: ['fal', 'plus'],
                to: { name: 'addHorse' },
            },
            {
                title: 'global.horse_statistique',
                icon: ['fal', 'horse'],
                to: { name: 'pensionListe'},
                accessRights: 'FACT'
            },
            {
                title: 'mouvement.chevaux_presents',
                icon: ['fal', 'farm'],
                to: { name: 'mouvementResidence' }
            },
            {
                title: 'lieu.localisation',
                icon: ['far', 'map-marker-alt'],
                to: { name: 'localisationResidence' }
            },
			{
				title: 'menu.setting.simulateur_croisement',
				icon: ['fal', 'horse'],
				accessRights: 'REPRO|SAILL',
				to: { name: 'simulateurCroisement' },
			},
   //          {
			// 	title: 'action.ajouter_cheval_transpondeur',
			// 	icon: ['fal', 'plus'],
			// 	to: { name: 'HorseAjoutByTranspondeur' },
			// }
        ]
    },
    {
        title: 'global.actes',
        icon: ['fal', 'tasks'],
        accessRights: 'MVP',
        children: [
            {
                title: 'global.planning',
                icon: ['fal', 'calendar-week'],
                to: { name: 'planning' },
                href: '#/planning/t='
            },
            {
                title: 'global.historique_actes',
                icon: ['fal', 'history'],
                to: { name: 'acteListe' },
                href: '#/acte_liste/t='
            },
            {
                title: 'global.historique_actes_online',
                icon: ['fal', 'history'],
                to: { name: 'acteListeOnline' },
                href: '#/acte_liste_online/t='
            },
            {
                title: 'global.analyse_sanitaire',
                icon: ['fal', 'file-medical'],
                to: { name: 'DemandeAnalyse' },
                href: '#/analyse_sanitaire'
            },
            {
                title: 'global.calendrier',
                icon: ['far', 'calendar'],
                to: { name: 'calendar' },
                href: '#/calendar'
            },
            {
                title: 'global.planning_task',
                icon: ['fal', 'calendar-week'],
                to: { name: 'TaskPlanning' },
                href: '#/task_planning'
            },
        ]
    },
    {
        title: 'global.mouvements',
        icon: ['fal', 'route'],
        accessRights: 'MVP',
        children: [
            {
                title: 'mouvement.historique_mouvement',
                icon: ['fal', 'history'],
                to: { name: 'mouvementListe' }
            },
            {
                title: 'deplacement.transport',
                icon: ['fad', 'truck'],
                to: { name: 'DeplacementListe' },
                href: '#/deplacement/list'
            },
            {
                title: 'mouvement.suggestion',
                icon: ['fal', 'venus-mars'],
                to: { name: 'SuggestionMouvementStallion' },
                href: '#/suggestion_mouvement/stallion'
            }
        ]
    },
    {
        title: 'global.mediatheque',
        icon: ['fal', 'photo-video'],
        to: { name: 'registryHistory' },
        accessRights: 'MVP'
    }
]
