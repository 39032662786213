export default [
    {
        title: 'menu.tiers.tiers',
        icon: ['fal', 'user-tie'],
        accessRights: 'MVP',
        children: [
            {
                title: 'menu.tiers.tous_les_tiers',
                icon: ['fal', 'users'],
                to: { name: 'tiersListe' },
                href: '#/tiers',
                accessRights: 'TIERS|FACT|REPRO'
            },
			{
                title: 'menu.tiers.tiers_archived',
                icon: ['fal', 'users'],
                to: { name: 'tiersArchivedListe' },
                href: '#/tiers_archived',
                accessRights: 'TIERS|FACT|REPRO'
            },
            {
                title: 'global.recherche',
                icon: ['fal', 'search'],
                to: { name: 'tiersSearch' },
                href: '#/tiers/search',
                accessRights: 'TIERS|FACT'
            },
			{
                title: 'menu.tiers.tous_les_contacts',
                icon: ['fal', 'search'],
                to: { name: 'contactList' },
                href: '#/contacts',
                accessRights: 'MVP'
            },
            {
                title: 'menu.tiers.contacts_archived',
                icon: ['fal', 'search'],
                to: { name: 'contactArchivedList' },
                href: '#/contacts_archived',
                accessRights: 'MVP'
            },
            {
				title: 'horse.ajouter_tiers_horse',
				icon: ['fal', 'plus'],
				to: { name: 'tiersAjout' },
                accessRights: 'TIERS|FACT'
			}
        ]
    },
    {
        title: 'menu.facturation.facturation',
        icon: ['fal', 'file-invoice'],
        accessRights: 'FACT|FOUROCR',
        children: [
            {
                title: 'menu.facturation.generer_facture',
                icon: ['fal', 'file-export'],
                to: { name: 'invoiceAValider' },
                href: '#/invoice/a_valider',
                accessRights: 'FACT'
            },
            {
                title: 'menu.facturation.bon_facturer',
                icon: ['fal', 'file-invoice'],
                to: { name: 'invoiceBons' },
                href: '#/invoice/bons',
                accessRights: 'FACT'
            },
            {
                title: 'menu.facturation.factures',
                icon: ['fal', 'file-invoice'],
                to: { name: 'invoiceList' },
                href: '#/invoice/list',
                accessRights: 'FACT'
            },
            {
                title: 'menu.facturation.reglements',
                icon: ['fal', 'money-check-edit-alt'],
                to: { name: 'paymentList' },
                href: '#/payments/list',
                accessRights: 'FACT'
            },
            {
                title: 'menu.facturation.impayes',
                icon: ['fal', 'receipt'],
                to: { name: 'impayesList' },
                href: '#/invoice/unpaid',
                accessRights: 'FACT'
            },
            {
                title: 'menu.facturation.quotation',
                accessRights: 'DEVIS',
                icon: ['far', 'signature'],
                to: { name: 'quotation' },
                href: '#/quotation'
            },
            {
                title: 'menu.facturation.bilan',
                icon: ['fal', 'receipt'],
                to: { name: 'InvoiceResults' },
                href: '#/invoice/results',
                accessRights: 'FACT'
            },
            {
                title: 'menu.facturation.regul_bancaire',
                accessRights: 'FACT&MULTICOMPTA',
                icon: ['fal', 'arrows-alt-h'],
                to: { name: 'regulBancaire' },
                href: '#/payments/regul'
            },
            {
                title: 'menu.facturation.facture_fournisseur',
                icon: ['fal', 'file-invoice'],
                accessRights: 'FOUROCR',
                to: { name: 'invoiceSupplierControl' },
                href: '#/invoice_supplier/control'
            },
            {
                title: 'menu.facturation.export_deb_des',
                accessRights: 'DEBDES',
                icon: ['fal', 'download'],
                to: { name: 'exportDES' },
                href: '#/export_des'
            },
            {
                title: 'menu.facturation.flux_bank',
                accessRights: 'FACT|FOUROCR',
                icon: ['far', 'list-ul'],
                to: { name: 'bankFlow' },
                href: '#/bank_flox'
            }
        ]
    }
]