export default [
    {
        title: 'menu.admin.admin',
        icon: ['fal', 'gavel'],
        accessRights: 'ADMN',
        children: [
            {
                title: 'menu.admin.statistiques',
                icon: ['fal', 'chart-pie-alt'],
                to: { name: 'statistiques' }
            },
            {
                title: 'menu.admin.gestion_licences',
                icon: ['fal', 'id-badge'],
                to: { name: 'licenceManagement' }
            },
            {
                title: 'menu.admin.connexion_compte_auto',
                icon: ['fal', 'id-badge'],
                to: { name: 'autolog' }
            },
            {
                title: 'menu.admin.export',
                icon: ['fal', 'chart-pie-alt'],
                to: { name: 'exportConfig' }
			},
			{
                title: 'menu.admin.manage_stallion_id',
                icon: ['fad', 'id-card'],
                to: { name: 'manageStallionNumber' }
            }
        ]
    }
]
