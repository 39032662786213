import Mvp from './mvp'
import Invoice from './invoice'
import Settings from './settings'
import Compte from './compte'
import Admin from './admin'
import ClientArea from './client_area'
import Monte from './monte'
import Reproduction from './reproduction'

let vm = null

export default {
	setInstance(v) {
		vm = v
	},
    all() {
        return []
        .concat(Mvp)
        .concat(Reproduction)
        .concat(Monte)
        .concat(Invoice)
		.concat(ClientArea)
        .concat(Settings)
        .concat(Compte)
        .concat(Admin)
    },
    user() {
        const menus = this.all()

        this.removeUnauthorizedMenus(menus)

        return menus
    },
    removeUnauthorizedMenus(menus) {
        let removed = 0
		const menusLen = menus.length
        for (let idx = 0; idx < menusLen; idx++) {
            let menu = menus[idx - removed]

			if(menu.accessRights
                && !vm.checkUserAccessRights(menu.accessRights)
            ) {
                menus.splice(idx - removed, 1)
                removed++
            }
            else if (menu.children) {
                this.removeUnauthorizedMenus(menu.children)
            }
        }
    }
}
