export default [
	{
		title: 'menu.reproduction.title',
		icon: ['fal', 'syringe'],
		accessRights: 'REPRO',
		children: [
			{
				title: 'menu.reproduction.bilan',
				icon: ['fal', 'file-export'],
				to: { name: 'etatJumenterie' },
				href: '#/bilans/jumenterie'
			},
			{
				title: 'menu.reproduction.juments_non_suivies',
				icon: ['fal', 'horse'],
				to: { name: 'jumentsNonSuivies' },
				href: '#/juments_non_suivies'
			},
			{
				title: 'menu.reproduction.embryon_management',
				icon: ['fal', 'baby'],
				to: { name: 'embryonManagement' },
				href: '#/reproduction/embryon/available',
				accessRights: 'SEMENCE'
			},
            {
                title: 'menu.monte.stock_semence_local',
                icon: ['fal', 'crutch'],
                to: { name: 'visualisationStock' },
                href: '#/monte/stock_local/visualisation',
                accessRights: 'SEMENCE'
            }
		]
	}
]
