export default [
	{
		title: 'menu.client_area.client_area',
        icon: ['fal', 'user-tie'],
        accessRights: 'DUCA',
        children: [
			{
				title: 'menu.client_area.gerer_type_actes_ca',
				icon: ['far', 'file'],
				accessRights: 'DUCA',
				to: { name: 'acteTypeClientArea' },
			},
			{
				title: 'menu.client_area.notif_management',
				icon: ['far', 'file'],
				accessRights: 'DUCA',
				to: { name: 'acteListeNotificationsClientArea' },
			},
			{
				title: 'menu.client_area.notif_note_management',
				icon: ['far', 'file'],
				accessRights: 'DUCA',
				to: { name: 'noteListeNotificationsClientArea' },
			},
			{
				title: 'menu.client_area.model_management',
				icon: ['far', 'file'],
				accessRights: 'DUCA',
				to: { name: 'modelClientArea' },
			},
			{
				title: 'menu.client_area.horses_unacknowledged',
				icon: ['fas', 'horse-head'],
				accessRights: 'DUCA',
				to: { name: 'horsesManagementCa' },
			},
			{
				title: 'menu.client_area.manage_contract_options',
				icon: ['fas', 'horse-head'],
				accessRights: 'DUCA',
				to: { name: 'contractCaOptions' },
			}
		]
	}

]