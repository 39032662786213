export default [
    {
        title: 'menu.compte.parametres_compte',
        icon: ['fal', 'user-cog'],
        accessRights: 'MVP',
        children: [
            {
                title: 'menu.compte.mes_factures',
                icon: ['fal', 'file-invoice'],
                accessRights: 'IFOURN|IFOURNC',
                to: { name: 'addressedInvoices' }
            },
            {
                title: 'menu.compte.mes_reglements',
                icon: ['fal', 'receipt'],
                accessRights: 'IFOURN|IFOURNC',
                to: { name: 'addressedPayments' }
            },
            {
                title: 'menu.compte.changement_licence',
                icon: ['fal', 'users'],
                accessRights: 'HMAL',
                to: { name: 'switchLicence' }
            },
            {
                title: 'menu.compte.display',
                icon: ['fal', 'desktop'],
                to: { name: 'displaySettings' }
            },
            {
                title: 'menu.compte.gestion_stockage',
                icon: ['fal', 'database'],
                to: { name: 'storageManagement' }
            },
            {
                title: 'global.parrainage',
                icon: ['fal', 'user-friends'],
                to: { name: 'parrainageFilleul' }
            },
            {
                title: 'menu.compte.import',
                icon: ['fal', 'user-friends'],
                accessRights: 'OHARAS',
                to: { name: 'import' }
            },
			{
                title: 'menu.compte.update',
                icon: ['fas', 'spinner'],
                to: { trad: "global.link_pwa_updater" }
            },
            {
                title: 'menu.compte.guides',
                icon: ['fal', 'film'],
                to: { trad: "global.guides_url" }
            },
            {
                title: 'menu.compte.inscription_webinaire',
                icon: ['far', 'camera'],
                accessRights: 'TRIGG',
                to: { url: "https://app.livestorm.co/equideclic/webinaire-groomy-formation?type=detailed" }
            },
            {
                title: 'menu.compte.support',
                icon: ['fal', 'user-cog'],
                accessRights: 'MVP',
                to: { name: 'support' }
            },
            {
                title: 'menu.compte.resiliation',
                icon: ['fal', 'times'],
                to: { name: 'resiliation' }
            },
        ]
    }
]
