<template>
    <div class="d-flex flex-column" id="main_menu_nav">
        <div class="row no-gutters align-items-center d-md-flex d-none">
            <router-link class="col col-logo"  :to="{ name: 'cards'}">
                <LogoMenuSvg />
				<span>{{ version }}</span>
            </router-link>
            <div class="col-auto col-menu" @click="changeMenuSizeUser">
                <i v-b-tooltip.hover.right :title="$t('menu.reduce')"><font-awesome-icon :icon="['fal', 'outdent']" /></i>
                <i v-b-tooltip.hover.right :title="$t('menu.expand')"><font-awesome-icon :icon="['fal', 'indent']" /></i>
            </div>
        </div>
        <ul :class="`sub_menu ${isNavOpen ? 'nav-opened' : 'nav-closed'}`">
            <h2 class="d-md-none">{{ $t('header.menu') }}<span class="d-block version_mobile">{{ version }}</span></h2>
            <div class="mb-4 mt-4 search">
                <SearchInput />
            </div>
            <li v-for="menu in menus" :key="menu.title">
                <a
                    @click.prevent="handleClic(menu.to)"
                    href="#"
                    :class="{ menuActive: menu.to && $route.name === menu.to.name }"
                    v-b-toggle="`collapse-${menu.title}`"
                >
                    <i
                        :disabled="hide_tooltip"
                        v-b-tooltip.right
                        :title="$t(menu.title)"
                    >
                        <font-awesome-icon :icon="menu.icon" />
                    </i>
                    {{ $t(menu.title) }}
                    <i v-if="menu.children" class="fal fa-angle-down">
                        <font-awesome-icon :icon="['fal', 'angle-down']"/>
                    </i>
                </a>

                <b-collapse
                    v-if="menu.children"
                    accordion="accordionMenu"
                    tag="ul"
                    class="submenu"
                    :id="`collapse-${menu.title}`"
                >
                    <li v-for="child in menu.children" :key="child.title">
                        <a
                            v-if="child.href"
                            :class="{ menuActive: child.to && $route.name === child.to.name }"
                   		 	:href="child.href"
		                    @click.prevent="handleClic(child.to)"
                        >
                            <i v-b-tooltip.right :title="$t(child.title)">
                                <font-awesome-icon :icon="child.icon" />
                            </i> {{ $t(child.title) }}
                        </a>
                        <a
                            v-else
                            :class="{ menuActive: child.to && $route.name === child.to.name }"
                   		 	href="#"
		                    @click.prevent="handleClic(child.to)"
                        >
                            <i v-b-tooltip.right :title="$t(child.title)">
                                <font-awesome-icon :icon="child.icon" />
                            </i> {{ $t(child.title) }}
                        </a>
                    </li>
                </b-collapse>
            </li>
        </ul>

        <NavFooter :class="isNavFooterOpen ? 'nav-footer-opened' : 'nav-footer-closed'" />
    </div>
</template>

<script type="text/javascript">
import Navigation from '@/mixins/Navigation.js'
import Window from '@/mixins/Window.js'
import Menus from '@/assets/js/menus'

export default {
	mixins: [Navigation, Window],
	components: {
		LogoMenuSvg: () => import('GroomyRoot/components/Logos/LogoMenu_111'),
		NavFooter: () => import('@/components/Header/NavFooter'),
		SearchInput : () => import('@/components/Inputs/SearchInput')
	},
    data() {
        return {
			menus: [],
			search_param: null,
            events_tab: {
                'User::AccessRightsReloaded': this.reloadMenus
            }
        }
    },
    computed: {
		actualRouteName() {
			return this.$route.name
		},
        hide_tooltip() {
            return this.isNavOpen
        },
        isNavOpen() {
            return this.$store.state.nav.isOpen
        },
        isNavFooterOpen() {
            return this.$store.state.nav.isFooterOpen
		},
		version() {
			return process.env.VUE_APP_BUILD_VERSION
		}
    },
    created() {
        window.addEventListener('wheel', this.disableScroll)

		Menus.setInstance(this)
        this.reloadMenus()
	},
	mounted() {
		this.resizeMenu()
	},
    destroyed() {
        window.removeEventListener('wheel', this.disableScroll)
    },
    methods: {
		handleClic(to) {
			if(!to) return

			if(to.name != this.actualRouteName) {
				this.$router.push(to)
			}

			if(to.url) { 
				window.open(to.url, '_blank') 
			}

			if(to.trad) {
				window.open(this.getTrad(to.trad), '_blank') 
			}

			this.$store.commit('nav/close')
		},
        reloadMenus() {
			this.menus = Menus.user()
        },
        disableScroll(event){
            if(document.activeElement.type === "number"){
                document.activeElement.blur();
            }
        },
        changeMenuSizeUser() {
            const open = !this.isNavOpen
            if(open) this.$store.commit('nav/open')
            else this.$store.commit('nav/close')

            this.updateUserMenuConfig('menu_open', open)
            this.resizeMenu()
        },
        resizeMenu() {
            if(this.isNavOpen) {
                document.querySelector('body').classList.remove('min')
            }
            else {
                document.querySelector('body').classList.add('min')
            }
		}
    }
}
</script>
